import { plugin_registry, PluginBase } from "../nk-plugin-registry";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import MAP_PIN_ICON from 'assets/map_pin.svg';

const MAP_URL = window.MAP_URL || 'https:////cafewagner.de/tiles/{z}/{x}/{y}.png'
const MAP_CENTER = [50.9253225,11.57908];
// const MAP_CENTER = [50.93120169466833, 11.580263503609933]; //old location wagnergasse
const MAP_ZOOM = 18
const POPUP_CONTENT = '<h4>Café Wagner</h4><p>Kochstraße 2a;<br>07745 Jena</p>'

@plugin_registry.register('PageFooter')
class PageFooter extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;

    this._map_pin_icon = L.icon({
      iconUrl: MAP_PIN_ICON,
      iconSize: [66, 92],
      iconAnchor: [33, 92],
      popupAnchor: [0, -50]
    });

  }

  loaded($node) {
    super.loaded($node);
    this._$map = this._$node.querySelector('[data-js-select="map"]');

    this._map = L.map(this._$map, {
      attributionControl: false,
      zoomControl: false,
      fullscreenControl: false,
      touchZoom: false,
      scrollWheelZoom: false,
    }).setView(MAP_CENTER, MAP_ZOOM)

    this._tile_layer = L.tileLayer(MAP_URL, {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this._map);

    this._marker = L.marker(
      MAP_CENTER,
      {
        icon: this._map_pin_icon,
        riseOnHover: true,
      }
    ).addTo(this._map);


    this._popup = this._marker.bindPopup(POPUP_CONTENT).openPopup();
  }
}
