import {plugin_registry, PluginBase} from "nk-plugin-registry";

import Glide from '@glidejs/glide';
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

import "../scss/image_gallery.scss";


const ELEMENT_SELECTOR = '[data-ref="hero[el]"]'

export function nextSiblings (el) {
  let siblings = []

  if (el) {
    while (el = el.nextElementSibling) {
      siblings.push(el);
    }
  }

  return siblings
}

export function prevSiblings (el) {
  let siblings = []

  if (el) {
    while (el = el.previousElementSibling) {
      siblings.push(el)
    }
  }

  return siblings
}

const coverflow_gen = (Glide, Components, Events) => {
  const COVERFLOW = {
    tilt(current) {
      // Reset transformations of the currently active element.
      current.querySelector(ELEMENT_SELECTOR).style.transform = `perspective(1500px) rotateY(0deg) scale(1.2)`
      current.querySelector(ELEMENT_SELECTOR).style.transformOrigin = '50% 50%'

      // Transform next and previous siblings of the currently active element
      this.tiltPrevElements(current)
      this.tiltNextElements(current)
    },

    tiltPrevElements(current) {
      let prev = prevSiblings(current)

      for (let i = 0; i < prev.length; i++) {
        let el = prev[i].querySelector(ELEMENT_SELECTOR)

        el.style.transformOrigin = '100% 50%'
        el.style.transform = `perspective(1500px) rotateY(${(Math.max(i, 2) * 20)}deg) scale(1)`
      }
    },

    tiltNextElements(current) {
      let next = nextSiblings(current)

      for (let i = 0; i < next.length; i++) {
        let el = next[i].querySelector(ELEMENT_SELECTOR)

        el.style.transformOrigin = '0% 50%'
        el.style.transform = `perspective(1500px) rotateY(${-(Math.max(i, 2) * 20)}deg) scale(1)`
      }
    }
  }

  Events.on(['mount.after', 'run'], () => {
    COVERFLOW.tilt(Components.Html.slides[Glide.index])
  })

  return COVERFLOW
}

const REVEAL_CONFIG = {
  focusAt: "center",
  startAt: 1,
  perView: 5,
  peek: 50,
  gap: 30,
  hoverpause: false,
  animationDuration: 1000,
  rewindDuration: 2000,
  perTouch: 1,
  breakpoints: {
    480: {
      gap: 15,
      peek: 75,
      perView: 1
    },
    768: {
      perView: 2
    },
    1440: {
      perView: 4
    },
  }
}

@plugin_registry.register('ImageGallery')
class ImageGallery extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
    this._glide = null;
    this._gallery_root = null;
  }

  loaded($node) {
    this._$glide_root = this._$node.querySelector('.glide');
    this._gallery_root = this._$node.querySelector('.glide__slides');

    this._viewer = new Viewer(
      this._gallery_root,
      {
        toolbar: {
          zoomIn: false,
          zoomOut: false,
          oneToOne: false,
          reset: false,
          prev: true,
          play: {
            show: false,
            size: 'large',
          },
          next: true,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        url: (image) => (image.dataset['originalUrl']),
      }
    );
    this._glide = new Glide(this._$glide_root, REVEAL_CONFIG).mount({ coverflow_gen });
  }

  disconnect($node) {
    super.disconnect($node);
    this._glide.destroy();
    this._glide = null;
    this._$glide_root = null;
    this._$node = null;
  }
}
