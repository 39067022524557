import gsap from "gsap";
import { plugin_registry, PluginBase} from "nk-plugin-registry"

import "components/MainNavigation.scss";


@plugin_registry.register("MainNavigation")
class PageHeader extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
  }

  connect($node) {
    super.connect($node);
  }

  loaded($node) {
    super.loaded($node);
    this._$button_close = this.$node.querySelector('[data-js-select="close-overlay"]');
    this._$button_open = this.$node.querySelector('[data-js-select="open-overlay"]');
    this._$navigation_overlay = this.$node.querySelector('[data-js-select="navigation-overlay"]')
    this._$body = document.body;

    this._attach_events();
  }

  _attach_events = () => {
    this._$button_close.addEventListener('click', this.close_navigation_overlay);
    this._$button_open.addEventListener('click', this.open_navigation_overlay);
  }

  close_navigation_overlay = () => {
    gsap.to(this._$navigation_overlay,
      {
        duration: 0.33,
        display: "none",
        opacity: 0,
        onComplete: () => {this._$body.style.overflow = "visible";},
      },
    );
  }

  open_navigation_overlay = () => {
    this._$body.style.overflow = "hidden";
    gsap.to(this._$navigation_overlay,
      {
        duration: 0.33,
        display: "flex",
        opacity: 1,
      },
    );
  }
}
