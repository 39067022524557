import {plugin_registry, PluginBase} from "nk-plugin-registry";

import "../scss/soundcloud_embed.scss";

const sound_cloud_instances = new Map();

@plugin_registry.register('SoundCloudEmbed')
class SoundCloudEmbed extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  connect($node) {
    super.connect($node);
    this._$node = $node;
    sound_cloud_instances.set(this._$node, this);
  }

  loaded($node) {
    super.loaded($node);
    this._$consent_overlay = this._$node.querySelector('.consent-overlay');
    this._$consent_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
    this._$embed_container = this._$node.querySelector('.iframe-root');
    this._$template = this._$node.querySelector('template');
    this._$consent_overlay_activate_button.addEventListener('click', this._activate_all)
  }

  disconnect($node) {
    super.disconnect($node);
    this._$node = null;
    this._$consent_overlay_activate_button = null;
    sound_cloud_instances.delete(this._$node);
  }

  activate = () => {
    this._$consent_overlay.classList.add('hide');
    this._$consent_overlay_activate_button.removeEventListener('click', this._activate);
    this._$consent_overlay_activate_button = null;
    this._$embed_container.appendChild(this._$template.content.cloneNode(true));
  }

  _activate_all = (event) => {
    this.activate();

    // activate all other video players
    for (const [$node, instance] of sound_cloud_instances) {
      if (this._$node !== $node) {
        instance.activate()
      }
    }
  }
}
