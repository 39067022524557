import {plugin_registry, PluginBase} from "nk-plugin-registry"

@plugin_registry.register('DayAndNight')
class DayAndNight extends PluginBase {
  constructor($node) {
    super($node);
    this.$contents = $node.querySelector('[data-js-select-contents]');
    this.$night = $node.querySelector('[data-js-select-night]');
    this.$day = $node.querySelector('[data-js-select-day]');
  }

  set contents(new_contents) {
    this._contents = new_contents;
    if (this._contents === 'night') {
      this.$contents.classList.add('show-night');
      this.$contents.classList.remove('show-day');
    } else {
      this.$contents.classList.add('show-day');
      this.$contents.classList.remove('show-night');
    }
  }

  get contents() {
    return this._contents;
  }

  should_show_for_current_time = () => {
    const time = new Date();
    const hours = time.getHours();
    if (hours < 15 && hours > 5) {
      return ('day')
    } else {
      return ('night');
    }
  }

  set_night = () => {
    this.contents = 'night'
    this.last_shown = 'night'
  }
  set_day = () => {
    this.contents = 'day'
    this.last_shown = 'day'
  }

  get last_shown() {
    return sessionStorage.getItem("day_and_night_last_shown");
  }

  set last_shown(new_value) {
    if (new_value === 'day' || new_value === 'night') {
      sessionStorage.setItem("day_and_night_last_shown", new_value);
    } else {
      throw Error('value for last_shown must either be "day" or "night".');
    }
  }

  connect($node) {
    super.connect($node);

    this.$night.addEventListener('click', this.set_night)
    this.$day.addEventListener('click', this.set_day)

    this.$night.addEventListener('touchend', this.set_night)
    this.$day.addEventListener('touchend', this.set_day)

    const should_show_time_based = this.should_show_for_current_time();

    if (this.last_shown) {
      this.contents = this.last_shown;
    } else {
      this.contents = should_show_time_based;
    }
  }
}

export {DayAndNight}
