import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';

// import your plugins here.
import './generics/DayAndNight.js';

import './components/MainNavigation';
import './components/PageFooter';

import '../../events/js/';

import 'bandcamp_embed/js/bandcamp_embed';
import 'image/js/image';
import 'image_gallery/js/image_gallery';
import 'mega_headline/js/mega_headline';
import 'menu_of_the_day/js/menu_of_the_day';
import 'soundcloud_embed/js/soundcloud_embed';
import 'youtube_video/js/youtube_video';

plugin_registry.init();
